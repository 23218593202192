import { _url, timeout } from "../configs/url";
import { customFetch } from "../configs/refreshToken";
import { STORAGE_KEYS } from "../common/constants/Storage";
import { getStorage } from "../common/constants/Services";

export async function getAccountInfo() {
    let url = _url.server + _url.apiGetAccountInfo;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}
export async function getUser(username) {
    let url = _url.server + _url.apiGetUserInfo + '/' + username;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}
export async function getUsers(page) {
    let url = _url.server + _url.apiGetUsers+"?page="+page;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function deleteUser(data) {
    let url = _url.server + _url.apiDeleteUser + '/' + data.id;
    let dataResponse = customFetch(url, data, 'DELETE', '');
    return dataResponse;
}

export async function updateStatus(status) {
    let url = _url.server + _url.apiStatusUser;
    let dataResponse = customFetch(url, status, 'POST', '');
    return dataResponse;
}

export async function updateFeature(data) {
    let url = _url.server + _url.apiFeatureUser;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function confirmPhoneNumber(data) {
    let url = _url.server + _url.apiConfirmPhoneNumber + '/' + data.id;;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function getApplyJobs(page, email, job, employee,jobId = null) {
    let urlEmail = email ? "&email=" + email : "";
    let urlJob = job ? "&job=" + job : "";
    let urlEmployee = employee ? "&employee=" + employee : "";
    if(jobId) urlJob += "&jobId=" + jobId
    let url = _url.server + _url.apiApplyJobList + "?page=" + page + urlEmail + urlJob + urlEmployee;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function getMyFile() {
    let url = _url.server + _url.apiGetMyFile;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function downloadFile(path) {
    let url = _url.server + _url.apiDownloadFile + '?path=' + path;
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.blob()));
}

export async function getViewCompany(username) {
    let url = _url.server + _url.apiViewCompany + '/' + username;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function getAllCompany(page, country, city, name) {
    let countryFilter = (country > 0) ? '&country=' + country : '';
    let cityFilter = (city > 0) ? '&city=' + city : '';
    let titleFilter = name ? '&name=' + name : '';
    let url = _url.server + _url.apiGetAllCompany + '?page=' + page + countryFilter + cityFilter + titleFilter;
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    if(token) header['Authorization'] = `Bearer ${token}`; // Add token to header if it exists
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function registerAccount(fullname, email, password, confirmPassword, type, token) {
    let url = _url.server + _url.apiRegister;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    let data = {
        fullName: fullname,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        type: type,
        token: token,
    }

    return timeout(fetch(url, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
    }).then(res => res.status == 200 ? res : res.json()));
}

export async function login(email, password) {
    let url = _url.server + _url.apiLogin;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    let data = {
        email: email,
        password: password,
    }
    return timeout(fetch(url, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)
    }).then(res => res.json()));
}

export async function forgotPassword(email) {
    let url = _url.server + _url.apiForgotPassword;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    let json = {
        email: email,
    }

    return timeout(fetch(url, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(json)
    }).then(res => res));
}

export async function changePassword(email, oldPassword, newPassword) {
    let url = _url.server + _url.apiChangePassword;
    let data = {
        newEmail: email,
        newPassword: newPassword,
        oldPassword: oldPassword,
    }
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function updateProfile(fullName, otherName, phone, address, cityId, website, yourself, employeeInfo) {
    let url = _url.server + _url.apiUpdateProfile;
    let data = {
        fullName: fullName,
        otherName: otherName,
        phone: phone,
        address: address,
        cityId: cityId,
        website: website,
        about: yourself,
        employeeInfo: employeeInfo
    }

    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function changeUserName(data) {
    let url = _url.server + _url.apiChangeUserName;

    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function updateAvatar(file) {
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let url = _url.server + _url.apiUpdateAvatar;
    const formData = new FormData();
    formData.append('file', file);

    let header = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    return timeout(fetch(url, {
        method: 'POST',
        headers: header,
        body: formData
    }).then(res => res));
}

export async function updateImage(file, num) {
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let url = _url.server + _url.apiUpdateImage + '?num=' + num;
    const formData = new FormData();
    formData.append('file', file);

    let header = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    return timeout(fetch(url, {
        method: 'POST',
        headers: header,
        body: formData
    }).then(res => res));
}

export async function updateFile(file, filetype) {
    let token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
    let url = _url.server + _url.apiUpdateFile + '?FileType=' + filetype;
    const formData = new FormData();
    formData.append('file', file);

    let header = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    return timeout(fetch(url, {
        method: 'POST',
        headers: header,
        body: formData
    }).then(res => res));
}

export async function confirmEmail(userId, code) {
    let url = _url.server + _url.apiConfirmEmail + '?userId=' + userId + '&code=' + code;
    return timeout(fetch(url, {
        method: 'POST',
    }).then(res => res));
}