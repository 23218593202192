import { em } from "@mantine/core";

export default class EmployeeModel {
    constructor(emp = {}) {
        this.fullName = emp.fullName || "";
        this.otherName = emp.otherName || "";
        this.userName = emp.userName || "";
        this.phone = emp.phone || "";
        this.address = emp.address || "";
        this.role = emp.role || "";
        this.avatar = emp.avatar || null;
        this.website = emp.website || "";
        this.isConfirmed = emp.isConfirmed || false;
        this.cityId = emp.cityId || 0;
        this.about = emp.about || "";
        this.email = emp.email || "";
        this.employeeInfoData = emp.infoData || {};
        this.candidateInfoData = emp.candidateInfoData || {};
    }
}
